<template>
	<main>
		<HeaderTab :title="$t('courrier_type.personnalisation_model')" />

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
                        <div class="box">
							 <e-select
					            v-model="model_type_selected"
					            id="model_type"
					            track-by="modeltype_id"
					            label="modeltype_label"
					            :options="model_types"
					            :placeholder="$t('courrier_type.placeholder_type')"
					            :selectedLabel="$t('courrier_type.placeholder_type')"
                                :selectLabel="$t('courrier_type.placeholder_type')"
					            :searchable="true"
					            :show-labels="false"
					            :allow-empty="false"
					            :disabled="readonly"
					            :loading="loadingType"
					        >
					            <template slot="singleLabel" slot-scope="{ option }">{{ option.modeltype_label }}</template>
					            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
					        </e-select>

							<div>
					    		<label>{{ $t('courrier_type.model_label') }}</label>
								<input type="text" class="form-control" :placeholder="$t('courrier_type.model_label')" v-model="model_label" :disabled="readonly">
							</div>

							<div v-if="!model || !model.model_id" class="text-center">
				                <b-button pill variant="primary" class="mt-3" @click.prevent="addModel"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
			                </div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="model && model.model_id" id="content">
			<div class="container-fluid">
				<div class="row">
					<div :class="(base64 ? 'col-6' : 'col')">
						<Edit ref="edit" :modelable_id="modelableId" :modelable_type="model_type_selected.modeltype_template" :model_id="model.model_id"  :preview_pdf.sync="preview_pdf" :save_pdf="true" :preview_type="preview_type" />
					</div>
				    <div v-if="base64" class="col-6">
						<iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
				    </div>
				</div>
			</div>
		</div>

		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.previsualiser") }}
			</template>
			
			<SearchHorse v-if="preview_type == 'horse'" :horse_selected.sync="entity_selected" />

			<SearchTiers v-if="preview_type == 'tiers'" :tiers_selected.sync="entity_selected" />

			<div class="text-center mt-3">
				<b-button variant="primary" @click.prevent="previewCourrier" pill >
					{{ $t('action.previsualiser') }} 
					<font-awesome-icon v-if="!busy" :icon="['far', 'file']" />
					<font-awesome-icon v-else :icon="['fas', 'spinner']" pulse />
				</b-button>
			</div>
		</b-modal>
	</main>
</template>

<script type="text/javascript">
    import Model from "@/mixins/Model.js";
    import Horse from "@/mixins/Horse.js";

	export default {
		name: "EditModel",
		mixins: [Model, Horse],
		data () {
			return {
				model_type_selected: {},
				model_types: [],
				model_label: "",
				readonly: false,
				blacklist_type: ['facture', 'contract_config', 'contract_generated', 'contract_adresses_generated'], //promis je suis pas raciste
				model: {},
				loadingType: false,
				processing: false,
				base64: null,
				preview_pdf: true,
				preview_type: null,
				entity_selected: null,
				busy: false
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.loadingType = true
				const model_types = await this.loadModelType(['contract_envoie', 'contract_envoie_adresse', 'contract_envoie_mail', 'validation_paiement_notify_ca', 'horse_envoie_infos',
					'courrier_tiers_envoie_entete', 'courrier_tiers_envoie', 'tiers_formatted', 'courrier_demande_expedition', 'contract_envoi_mail_invoice', 'bilanrepro_notification',
					'tiers_notification', 'contract_document_envoie', 'quotation_document_notification', 'flux_paillettes', 'creneaux_saillie', 'invitation_ca', 'actes', 'relance_centre', 'mail_notes', 'extrait_compte', 'semence_campagne'])//, 'tiers_envoie', 'horse_envoie'])
				this.model_types = model_types.filter(type => !this.blacklist_type.includes(type.modeltype_template))
				this.loadingType = false

				const model_id = this.$route.params.model_id
				if(model_id > 0) {
					this.readonly = true
					this.model = await this.loadModelWithoutType(model_id)
					this.model_label = this.model.model_label
					this.model_type_selected = this.model_types.find(type => type.modeltype_id == this.model.model_modeltype)
					if(this.model.modelable_type.includes('Tiers')) {
						this.preview_type = 'tiers'
					}
					else if(this.model.modelable_type.includes('Horse')) {
						this.preview_type = 'horse'
					}
				}
			},

			async addModel() {
				this.processing = true
				this.model = await this.createModel(0, this.model_type_selected.modeltype_id, this.model_label, this.model_type_selected.modeltype_template)
				this.processing = false
				this.readonly = true
			},

			openModalPreviewCourrier() {
				this.$refs.modal.show()
			},

			async previewCourrier() {
				this.busy = true

				if(this.preview_type == 'tiers') {
					const entity_id = this.entity_selected.tiers_id

					await this.$refs.edit.saveForm()
					const new_model = await this.createModel(entity_id, this.model_type_selected.modeltype_id, this.model.model_label, this.model_type_selected.modeltype_template)

					await this.cloneModel(this.model.model_id, new_model.model_id)
					await this.generateCourrier(new_model.model_id, this.model_type_selected.modeltype_template, entity_id)

					this.base64 = await this.printCourrier(new_model.model_id, this.model.model_label, true)
				}
				if(this.preview_type == 'horse') {
					this.base64 = await this.print_infos_horse(this.entity_selected.horse_id, 5, this.model.model_id, 0, true)
				}

				this.busy = false
				this.$refs.modal.hide()
				this.preview_type = null
				this.entity_selected = null
			}
		},
		computed: {
			modelableId () {
				return 0 //this.$route.params.modelable_id
			},
		},
		watch: {
			preview_pdf(val) {
				this.openModalPreviewCourrier()
			}
		},
		components: {
			HeaderTab : () => import('@/components/HeaderTab'),
			Edit : () => import('@/components/Model/Edit'),
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
			SearchHorse : () => import('@/components/Contract/SearchHorse')
		}
	}

</script>